
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetServerSideProps } from 'next';
import { getServerSession } from 'next-auth';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { ROUTE } from '@elendi/util-endpoints';
import { authOptions } from '@elendi/feature-authentication/data-access';
import { Skeleton } from '@elendi/feature-authentication/ui';
import { defaultSignIn } from '@elendi/feature-authentication/utils';
const Login = () => {
    const router = useRouter();
    const { status } = useSession();
    const prefill = String(router.query['prefill']);
    if (status === 'loading')
        return <Skeleton />;
    if (status === 'unauthenticated')
        defaultSignIn({ prefill, callbackUrl: ROUTE.index });
    if (status === 'authenticated')
        router.push(ROUTE.index);
    return <Skeleton />;
};
const getServerSideProps: GetServerSideProps = async (context) => {
    const session = await getServerSession(context.req, context.res, authOptions);
    if (!session)
        return { props: {} };
    return { redirect: { destination: ROUTE.user.home, permanent: false } };
};
export default Login;

    async function __Next_Translate__getServerSideProps__193200e2ba6__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__193200e2ba6__ as getServerSideProps }
  